<template>
  <div class="about">
    <div class="flex-2">
      <div class="panel">
        <h4>简介：</h4>
        <p>人间花絮由Rain燕子于2020年在上海创建。<br />是一个地图图片、视频资讯平台。<br />直观地为用户展示每天世界各地的美好与精彩。</p>
        <div>
          <h4>联系方式：</h4>
          <p>电话：19121454659 <br> 邮箱：343410096@qq.com</p>
        </div>
      </div>
    </div>
    <div class="footer flex-1">
      <div class="copyright">&copy;2021</div>
      <div class="company">上海嘤其鸣网络科技有限公司</div>
      <div class="icp">&nbsp;沪ICP备 2021034677号-1</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.about {
  height: 100%;
  background-color: #e1e0c7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "SimSun";
  .panel {
    margin-top: -10%;
  }
  .panel h4 {
    font-size: 2.4rem;
  }
  .panel p {
    font-size: 2rem;
  }
  .flex-1,
  .flex-2 {
    display: flex;
    align-items: center;
  }
  .flex-1 {
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .flex-2 {
    flex: 2;
    justify-content: center;
  }
  .footer {
    padding: 1.5rem 0;
    color: #999;
    font-size: 1.2rem;
  }
  .copyright {
    font-size: 1.4rem;
  }
  .verticle-mode {
    writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;      
    writing-mode: vertical-rl;
    p {
      line-height: 1.5;
    }
  }
}
</style>